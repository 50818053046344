import * as React from 'react';
import { useEffect } from 'react';

import { Hero, Layout, SEO } from '../../components';
import MovingQuoteForm from '../../components/forms/moving-quote-form';
import Banner from '../../components/sm/moving-banner';
import { HeroCTA } from '../../components/sm/hero-cta';
import FirstSection from '../../components/sm/moving-first-section';
import HeroImage from '../../components/sm/moving-hero-image';
import SecondSection from '../../components/sm/moving-second-section';
import SMSolutions from '../../components/sm-solutions';
import NoLimitations from '../../components/sm/no-limitations';

function ValidateForm() {
  let firstName = document.forms.newformtop.SingleLine2.value;
  let lastName = document.forms.newformtop.SingleLine3.value;
  let email = document.forms.newformtop.Email.value;
  let phoneNum = document.forms.newformtop.PhoneNumber_countrycode.value;
  let success = true;

  document
    .getElementById('SingleLine2_error')
    ?.classList.replace('block', 'hidden');

  document
    .getElementById('SingleLine3_error')
    ?.classList.replace('block', 'hidden');

  document.getElementById('Email_error')?.classList.replace('block', 'hidden');

  document
    .getElementById('PhoneNumber_error')
    ?.classList.replace('block', 'hidden');

  if (firstName === null || firstName === '') {
    document
      .getElementById('SingleLine2_error')
      ?.classList.replace('hidden', 'block');
    success = false;
  }
  if (lastName === null || lastName === '') {
    document
      .getElementById('SingleLine3_error')
      ?.classList.replace('hidden', 'block');
    success = false;
  }
  if (email === null || email === '') {
    document
      .getElementById('Email_error')
      ?.classList.replace('hidden', 'block');
    success = false;
  }
  if (phoneNum === null || phoneNum === '') {
    document
      .getElementById('PhoneNumber_error')
      ?.classList.replace('hidden', 'block');
    success = false;
  }

  return success;
}

function NewForm() {
  return (
    <div className="relative block max-w-4xl mx-auto -mt-24 xl:hidden">
      <form
        action="https://forms.zohopublic.com.au/frontlineremovals/form/REAInstantQuoteNEW/formperma/KWdRl8R_g-Su5FhWMylwsXwz2IlipogOsupXCsJ9s8g/htmlRecords/submit"
        name="newformtop"
        method="POST"
        accept-charset="UTF-8"
        encType="multipart/form-data"
        id="newformtop"
      >
        <input type="hidden" name="zf_referrer_name" />
        <input type="hidden" name="zf_redirect_url" />
        <input type="hidden" name="zc_gad" />
        <input type="hidden" name="utm_source" />
        <input type="hidden" name="utm_medium" />
        <input type="hidden" name="utm_campaign" />
        <input type="hidden" name="utm_term" />
        <input type="hidden" name="utm_content" />
        <input type="hidden" name="gclid" />
        <input type="hidden" name="referrername" />
        <div className="px-12 pt-20 pb-6 mt-12 bg-brand-blue">
          <div className="">
            <ul>
              <div className="w-full">
                <h1 className="text-5xl font-semibold leading-none text-white uppercase heading font-display">
                  Enter your details here for your{' '}
                  <span className="inline-block text-brand-orange">
                    quote
                  </span>
                </h1>
              </div>
              <li className="my-6">
                <label className="">
                  <div className="">
                    <span className="text-white rich-text">
                      Our customer service team works Monday to Friday 9am - 5pm
                      and will contact you as soon as possible.
                    </span>
                    <br />
                  </div>
                </label>
              </li>
              <li className="">
                <div className="">
                  <span>
                    <input
                      className="w-full px-3 py-3 mb-3"
                      type="text"
                      name="SingleLine2"
                      maxLength={255}
                      placeholder="First Name*"
                    />
                  </span>{' '}
                  <p id="SingleLine2_error" className="hidden zf-errorMessage">
                    Please enter your first name
                  </p>
                </div>
              </li>
              <li className="">
                <div className="">
                  <span>
                    <input
                      className="w-full px-3 py-3 mb-3"
                      type="text"
                      name="SingleLine3"
                      checktype="c1"
                      maxlength="255"
                      fieldType={1}
                      placeholder="Last Name*"
                    />
                  </span>{' '}
                  <p id="SingleLine3_error" className="hidden zf-errorMessage">
                    Please enter your last name
                  </p>
                </div>
              </li>
              <li className="">
                <div className="">
                  <span>
                    <input
                      className="w-full px-3 py-3 mb-3"
                      fieldType={9}
                      type="text"
                      maxlength="255"
                      name="Email"
                      checktype="c5"
                      placeholder="Email Address*"
                    />
                  </span>{' '}
                  <p id="Email_error" className="hidden zf-errorMessage">
                    Please enter your email address
                  </p>
                </div>
              </li>
              <li className="">
                <div className=" zf-phonefld">
                  <div className="zf-phwrapper zf-phNumber">
                    <span>
                      <input
                        className="w-full px-3 py-3"
                        type="text"
                        compname="PhoneNumber"
                        name="PhoneNumber_countrycode"
                        maxlength="20"
                        checktype="c7"
                        phoneFormat="1"
                        isCountryCodeEnabled={false}
                        fieldType="11"
                        id="international_PhoneNumber_countrycode"
                        valType="number"
                        phoneFormatType="1"
                        placeholder="Contact Number*"
                      />
                    </span>
                  </div>
                  <p
                    id="PhoneNumber_error"
                    className="hidden zf-errorMessageNoMB"
                  >
                    Please enter your phone number
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="mt-20 mb-4">
            <button
              onClick={(e) => {
                if (!ValidateForm()) e.preventDefault();
              }}
              className="flex items-center justify-center px-6 py-2 font-semibold text-white rounded bg-brand-orange border-brand-orange "
            >
              GET QUOTE NOW!
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
// function NewFormWorkingBackup() {
//   return (
//     <div className="zf-templateWidth">
//       <form
//         action="https://forms.zohopublic.com.au/frontlineremovals/form/REAInstantQuoteNEW/formperma/KWdRl8R_g-Su5FhWMylwsXwz2IlipogOsupXCsJ9s8g/htmlRecords/submit"
//         name="newformtop"
//         method="POST"
//         accept-charset="UTF-8"
//         encType="multipart/form-data"
//         id="newformtop"
//       >
//         <input type="hidden" name="zf_referrer_name" />
//         <input type="hidden" name="zf_redirect_url" />
//         <input type="hidden" name="zc_gad" />
//         <input type="hidden" name="utm_source" />
//         <input type="hidden" name="utm_medium" />
//         <input type="hidden" name="utm_campaign" />
//         <input type="hidden" name="utm_term" />
//         <input type="hidden" name="utm_content" />
//         <input type="hidden" name="gclid" />
//         <input type="hidden" name="referrername" />
//         <div className="zf-templateWrapper">
//           <ul className="zf-tempHeadBdr">
//             <li className="zf-tempHeadContBdr">
//               <h2 className="zf-frmTitle">
//                 <em>REA Instant Quote NEW</em>
//               </h2>
//               <p className="zf-frmDesc">Frontline Removals Website Form</p>
//               <div className="zf-clearBoth"></div>
//             </li>
//           </ul>
//           <div className="zf-subContWrap zf-topAlign">
//             <ul>
//               <li className="zf-tempFrmWrapper zf-note">
//                 <label className="zf-descFld">
//                   <div style={{ textAlign: 'left' }} className="align-left">
//                     <hr />
//                     <span className="size" style={{ fontSize: '32px' }}>
//                       <b>
//                         <span
//                           className="font"
//                           style={{ fontFamily: 'lato, sans-serif' }}
//                         >
//                           <span
//                             className="colour"
//                             style={{ color: 'rgb(0, 0, 0)' }}
//                           >
//                             ENTER YOUR DETAILS HERE FOR{' '}
//                           </span>
//                         </span>
//                       </b>
//                     </span>
//                     <span
//                       className="colour"
//                       style={{ color: 'rgb(255, 255, 255)' }}
//                     >
//                       <br />
//                     </span>
//                   </div>
//                   <div style={{ textAlign: 'left' }} className="align-left">
//                     <span
//                       className="colour"
//                       style={{ color: 'rgb(255, 255, 255)' }}
//                     >
//                       <br />
//                     </span>
//                   </div>
//                   <div style={{ textAlign: 'left' }} className="align-left">
//                     <span className="size" style={{ fontSize: '32px' }}>
//                       <b>
//                         <span
//                           className="font"
//                           style={{ fontFamily: 'lato, sans-serif' }}
//                         >
//                           <span
//                             className="colour"
//                             style={{ color: 'rgb(0, 0, 0)' }}
//                           >
//                             YOUR&nbsp;
//                           </span>
//                         </span>
//                       </b>
//                     </span>
//                     <span
//                       className="colour"
//                       style={{ color: 'rgb(255, 127, 0)' }}
//                     >
//                       <span className="size" style={{ fontSize: '32px' }}>
//                         <b>
//                           <span
//                             className="font"
//                             style={{ fontFamily: 'lato, sans-serif' }}
//                           >
//                             INSTANT QUOTE
//                           </span>
//                         </b>
//                       </span>
//                     </span>
//                     <br />
//                   </div>
//                 </label>
//                 <div className="zf-clearBoth"></div>
//               </li>
//               <li className="zf-tempFrmWrapper zf-note">
//                 <label className="zf-descFld">
//                   <div style={{ textAlign: 'left' }} className="align-left">
//                     <span className="size" style={{ fontSize: '16px' }}>
//                       <span
//                         className="font"
//                         style={{ fontFamily: 'lato, sans-serif' }}
//                       >
//                         <span
//                           className="colour"
//                           style={{ color: 'rgb(255, 255, 255)' }}
//                         >
//                           Our customer service team works Monday to Friday 9am -
//                           5pm and will contact you as soon as possible.
//                         </span>
//                       </span>
//                     </span>
//                     <br />
//                   </div>
//                 </label>
//                 <div className="zf-clearBoth"></div>
//               </li>
//               <li className="zf-tempFrmWrapper zf-large">
//                 <label className="zf-labelName"></label>
//                 <div className="zf-tempContDiv">
//                   <span>
//                     {' '}
//                     <input
//                       type="text"
//                       name="SingleLine2"
//                       maxLength={255}
//                       placeholder="First Name*"
//                     />
//                   </span>{' '}
//                   <p
//                     id="SingleLine2_error"
//                     className="hidden zf-errorMessage"
//                   >
//                     Please enter your first name
//                   </p>
//                 </div>
//                 <div className="zf-clearBoth"></div>
//               </li>
//               <li className="zf-tempFrmWrapper zf-large">
//                 <label className="zf-labelName"></label>
//                 <div className="zf-tempContDiv">
//                   <span>
//                     {' '}
//                     <input
//                       type="text"
//                       name="SingleLine3"
//                       checktype="c1"
//                       maxlength="255"
//                       fieldType={1}
//                       placeholder="Last Name*"
//                     />
//                   </span>{' '}
//                   <p
//                     id="SingleLine3_error"
//                     className="hidden zf-errorMessage"
//                   >
//                     Please enter your last name
//                   </p>
//                 </div>
//                 <div className="zf-clearBoth"></div>
//               </li>
//               <li className="zf-tempFrmWrapper zf-large">
//                 <label className="zf-labelName"></label>
//                 <div className="zf-tempContDiv">
//                   <span>
//                     {' '}
//                     <input
//                       fieldType={9}
//                       type="text"
//                       maxlength="255"
//                       name="Email"
//                       checktype="c5"
//                       placeholder="Email Address*"
//                     />
//                   </span>{' '}
//                   <p
//                     id="Email_error"
//                     className="hidden zf-errorMessage"
//                   >
//                     Please enter your email address
//                   </p>
//                 </div>
//                 <div className="zf-clearBoth"></div>
//               </li>
//               <li className="zf-tempFrmWrapper zf-large">
//                 <label className="zf-labelName"></label>
//                 <div className="zf-tempContDiv zf-phonefld">
//                   <div className="zf-phwrapper zf-phNumber">
//                     <span>
//                       {' '}
//                       <input
//                         type="text"
//                         compname="PhoneNumber"
//                         name="PhoneNumber_countrycode"
//                         maxlength="20"
//                         checktype="c7"
//                         phoneFormat="1"
//                         isCountryCodeEnabled={false}
//                         fieldType="11"
//                         id="international_PhoneNumber_countrycode"
//                         valType="number"
//                         phoneFormatType="1"
//                         placeholder="Contact Number*"
//                       />
//                     </span>
//                     <div className="zf-clearBoth"></div>
//                   </div>
//                   <p
//                     id="PhoneNumber_error"
//                     className="hidden zf-errorMessage"
//                   >
//                     Please enter your phone number
//                   </p>
//                 </div>
//                 <div className="zf-clearBoth"></div>
//               </li>
//             </ul>
//           </div>
//           <ul>
//             <li className="zf-fmFooter">
//               <button onClick={(e)=>{if (!ValidateForm()) e.preventDefault();}} className="zf-submitColor">Submit</button>
//             </li>
//           </ul>
//         </div>
//       </form>
//     </div>
//   );
// }

const REAInstantQuote = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      var f = document.createElement('iframe');
      f.src =
        'https://forms.zohopublic.com.au/frontlineremovals/form/REAInstantQuote/formperma/LDTZGgbeNe5F99hc8C2eLFfMi-4IZ_-Ri0HN2yIiv4o?zf_rszfm=1';
      f.style.border = 'none';
      f.style.height = '655px';
      f.style.width = '100%';
      f.style.zIndex = '50';
      f.style.transition = 'all 0.5s ease'; // No I18N
      var d = document.getElementById('moving-page-top-form');
      d.appendChild(f);
    }
  }, []);

  return (
    <Layout formName="moving-page-bottom-form" isREAPage>
      <SEO
        title="Interstate Furniture Removals | Frontline Removals & Storage"
        description="New South Wales interstate furniture removals and relocation company. Frontline Removals offers reliable & fast shipping of your belongings. Get a quote now."
      />
      <Hero
        image={<HeroImage />}
        cta={
          <HeroCTA
            isREAPage
            heading={
              <>
                packing boxes for 25 years <br />
                <span className="text-brand-orange">contact us today</span>
              </>
            }
          />
        }
      />
      {/* <NewForm/> */}
      <div
        id="moving-page-top-form"
        className="relative max-w-4xl mx-auto -mt-20 lg:-mt-36"
      ></div>

      <FirstSection />
      <SMSolutions />
      <SecondSection />
      <NoLimitations />
      <Banner />
    </Layout>
  );
};

export default REAInstantQuote;
